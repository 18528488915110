<template>
  <ribbon class="has-background-dark">
    <div class="columns is-marginless is-multiline">
      <div class="column is-4 is-3-widescreen">
        <screenshot
          ref="screenshot"
          :filename="site.screenshot"
          :title="site.fqdn"
          :refreshable="['owner', 'admin'].includes(siteUserRole)"
          max-width="24rem"
          classes="has-background-black"
          @refresh="recaptureScreenshot"
        />
      </div>

      <div id="site-header" class="column is-8 is-9-widescreen">
        <div class="is-stretched has-text-white has-text-centered-mobile">
          <h2 class="is-size-2 has-overflow-ellipsis-right">
            <strong>{{ site.fqdn }}</strong>
          </h2>

          <p>
            <a :href="site.url" target="_blank" class="has-text-success">
              <i class="fas fa-globe has-margin-right-50"></i>
              <u>{{ site.url }}</u>
            </a>
          </p>

          <p
            v-if="
              site.software === 'wordpress' &&
                $_.get(site, 'wordpress.hasPlugin')
            "
            class="has-margin-bottom-25"
          >
            <a
              target="_blank"
              :disabled="generatingNonce"
              class="has-text-success"
              @click="loginToWordpress"
            >
              <i
                class="has-margin-right-50"
                :class="{
                  'fab fa-wordpress': !generatingNonce,
                  'fas fa-spinner is-rotating': generatingNonce
                }"
              ></i>
              <u>Login to <strong>WordPress</strong></u>
            </a>
          </p>

          <div v-if="site.hostingData && $_.isArray(site.hostingData)">
            <p v-for="(hosting, index) in site.hostingData" :key="index">
              <a
                v-if="site.hostingData"
                class="fxd-cpanel-login has-text-warning"
                @click="loginCPanel(hosting)"
              >
                <i
                  class="fas"
                  :class="{
                    'fa-lock': !hosting.processing,
                    'fa-spinner fa-pulse': hosting.processing
                  }"
                ></i>
                <span>Login to</span>
                <i class="fab fa-cpanel" />
                <span v-if="site.hostingData.length > 1">{{
                  ` – ${hosting.hostname}`
                }}</span>
              </a>
            </p>
          </div>

          <p class="is-size-7 has-margin-y-25">
            Site added {{ $moment(site.dateCreated.toDate()).from() }}.
          </p>

          <p class="is-size-7">
            <b-icon
              :icon="roles[siteUserRole].icon"
              size="is-small"
              class="has-margin-right-50"
            />
            <span>{{ roles[siteUserRole].label }}</span>
          </p>
        </div>
      </div>
    </div>
  </ribbon>
</template>

<script>
export default {
  name: "SiteHeader",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      generatingNonce: false,
      roles: {
        owner: {
          label: "Owner",
          icon: "crown"
        },
        delegate: {
          label: "Delegate",
          icon: "user"
        },
        admin: {
          label: "Admin",
          icon: "shield-alt"
        },
        agent: {
          label: "Agent",
          icon: "user-tie"
        }
      }
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    },
    siteUserRole() {
      return this.isResellerUser
        ? "owner"
        : this.$store.getters["sites/site/userRole"](this.siteId);
    },
    dateScreenshotCaptureTimestamp() {
      return this.site.dateScreenshotCaptur
        ? this.site.dateScreenshotCapture.toDate().getTime()
        : 0;
    }
  },
  watch: {
    dateScreenshotCaptureTimestamp() {
      this.$nextTick(() => {
        this.$refs.screenshot.reset();
      });
    }
  },
  created() {
    this.setHostingInfo();
  },
  methods: {
    setHostingInfo() {
      // Cache hosting data for 1h
      if (
        !this.site.hostingDateUpdated ||
        this.$moment(this.site.hostingDateUpdated.toDate())
          .add(1, "hour")
          .isBefore(new Date())
      ) {
        this.$store
          .dispatch("sites/setHostingInfo", {
            siteId: this.siteId
          })
          .catch(() => {
            //hosting for site not found
          });
      }
    },
    loginCPanel(hostingData) {
      if (hostingData.processing || !hostingData) return;
      this.loadingCPanelLink = true;
      this.$set(hostingData, "processing", true);
      this.$store
        .dispatch("sites/callExternalAPI", {
          url: hostingData.loginlink
        })
        .then(response => {
          if (response.status === 200) {
            window.open(response.data, "_blank");
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error logging into cPanel",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.$set(hostingData, "processing", false);
        });
    },
    loginToWordpress() {
      if (this.generatingNonce) return;
      this.generatingNonce = true;
      this.$store
        .dispatch("sites/loginToWordpress", {
          siteId: this.siteId
        })
        .then(({ nonce }) => {
          window.open(
            `${this.site.url}?nonce=${nonce}&autologin=true`,
            "_blank"
          );
        })
        .catch(() => {
          this.$toast.open({
            message: "Error logging into WordPress",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.generatingNonce = false;
        });
    },
    recaptureScreenshot() {
      this.$store
        .dispatch("sites/recaptureScreenshot", { siteId: this.siteId })
        .then(result => {
          this.$toast.open({
            message: result.message
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "Error submitting recapture request",
            type: "is-danger"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
#site-header {
  @include tablet {
    display: flex;
    align-items: center;
    padding-left: 2em;
    padding-bottom: 1em;
  }
}
.fxd-cpanel-login {
  margin-top: 0.25rem;
  display: inline-flex;
  align-items: center;
  i:first-of-type {
    margin-right: 0.5rem;
  }
  i.fa-cpanel {
    font-size: 2.6125rem;
    line-height: 1rem;
    margin: 0 0.25rem;
  }
}
</style>
