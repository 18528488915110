var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ribbon',{staticClass:"has-background-dark"},[_c('div',{staticClass:"columns is-marginless is-multiline"},[_c('div',{staticClass:"column is-4 is-3-widescreen"},[_c('screenshot',{ref:"screenshot",attrs:{"filename":_vm.site.screenshot,"title":_vm.site.fqdn,"refreshable":['owner', 'admin'].includes(_vm.siteUserRole),"max-width":"24rem","classes":"has-background-black"},on:{"refresh":_vm.recaptureScreenshot}})],1),_c('div',{staticClass:"column is-8 is-9-widescreen",attrs:{"id":"site-header"}},[_c('div',{staticClass:"is-stretched has-text-white has-text-centered-mobile"},[_c('h2',{staticClass:"is-size-2 has-overflow-ellipsis-right"},[_c('strong',[_vm._v(_vm._s(_vm.site.fqdn))])]),_c('p',[_c('a',{staticClass:"has-text-success",attrs:{"href":_vm.site.url,"target":"_blank"}},[_c('i',{staticClass:"fas fa-globe has-margin-right-50"}),_c('u',[_vm._v(_vm._s(_vm.site.url))])])]),(
            _vm.site.software === 'wordpress' &&
              _vm.$_.get(_vm.site, 'wordpress.hasPlugin')
          )?_c('p',{staticClass:"has-margin-bottom-25"},[_c('a',{staticClass:"has-text-success",attrs:{"target":"_blank","disabled":_vm.generatingNonce},on:{"click":_vm.loginToWordpress}},[_c('i',{staticClass:"has-margin-right-50",class:{
                'fab fa-wordpress': !_vm.generatingNonce,
                'fas fa-spinner is-rotating': _vm.generatingNonce
              }}),_c('u',[_vm._v("Login to "),_c('strong',[_vm._v("WordPress")])])])]):_vm._e(),(_vm.site.hostingData && _vm.$_.isArray(_vm.site.hostingData))?_c('div',_vm._l((_vm.site.hostingData),function(hosting,index){return _c('p',{key:index},[(_vm.site.hostingData)?_c('a',{staticClass:"fxd-cpanel-login has-text-warning",on:{"click":function($event){return _vm.loginCPanel(hosting)}}},[_c('i',{staticClass:"fas",class:{
                  'fa-lock': !hosting.processing,
                  'fa-spinner fa-pulse': hosting.processing
                }}),_c('span',[_vm._v("Login to")]),_c('i',{staticClass:"fab fa-cpanel"}),(_vm.site.hostingData.length > 1)?_c('span',[_vm._v(_vm._s(` – ${hosting.hostname}`))]):_vm._e()]):_vm._e()])}),0):_vm._e(),_c('p',{staticClass:"is-size-7 has-margin-y-25"},[_vm._v(" Site added "+_vm._s(_vm.$moment(_vm.site.dateCreated.toDate()).from())+". ")]),_c('p',{staticClass:"is-size-7"},[_c('b-icon',{staticClass:"has-margin-right-50",attrs:{"icon":_vm.roles[_vm.siteUserRole].icon,"size":"is-small"}}),_c('span',[_vm._v(_vm._s(_vm.roles[_vm.siteUserRole].label))])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }